<template>
  <v-row>
    <v-col cols="12" class="pt-6">
      <h2>{{ $t("eventCategory") }}</h2>
      <v-select
        :loading="preloader"
        v-model="categories_name"
        :items="data"
        item-text="description"
        item-value="initial"
        :label="$t('eventCategory')"
        :hint="$t('selectEventCategoryHint')"
        :persistent-hint="true"
        @change="fetchEvents()"
        class="my-4"
        outlined
        dense
      ></v-select>
    </v-col>

    <v-col cols="12" v-if="provider_events.length > 0">
      <h2 class="mb-4">
        Folgende Termine werden von {{ categories_name }} importiert:
      </h2>
      <v-data-table
        dense
        :headers="[
          { text: 'Datum', value: 'tourn_date_first' },
          { text: 'Startzeit', value: 'startinfozeit' },
        ]"
        :items="provider_events"
        item-key="id"
        :loading="fetchingEvents"
        class="elevation-1"
      >
        <template v-slot:[`item.tourn_date_first`]="{ item }">
          <span
            v-if="
              item.tourn_date_first &&
              item.tourn_date_first !== '0000-00-00 00:00:00'
            "
            >{{ formatDateNoTime(item.tourn_date_first) }}</span
          >
        </template>
      </v-data-table>

      <v-btn
        large
        color="green"
        dark
        class="my-4"
        :loading="importingEvents"
        @click="importSelectedEvents"
      >
        {{ $t("importEvents") }}*
        <v-icon right dark>mdi-database-import</v-icon>
      </v-btn>

      <br /><small>*) {{ $t("importEventsInfo2") }}</small>
    </v-col>
  </v-row>
</template>

<script>
import store from "@/store";
import apiRequest from "@/mixins/apiRequest";
import helpers from "@/mixins/helpers";
export default {
  name: "ImportEvents",
  data() {
    return {
      data: [],
      providers_id: 15,
      provider_events: [],
      categories_name: "",
      companyID: store.getters["auth/current_sid"],
      preloader: true,
      fetchingEvents: false,
      importingEvents: false,
    };
  },

  mounted() {
    this.getCategories();
  },

  methods: {
    async getCategories() {
      // GET /companies/{id}/addons/eventcalendar/external/{providerId}/fetchEventCategories
      this.preloader = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/addons/eventcalendar/external/${this.providers_id}/fetchEventCategories`
      );
      this.data = response.data.data;
      this.preloader = false;
    },

    async fetchEvents() {
      // GET GET /companies/{id}/addons/eventcalendar/external/{providerId}/fetchEvents
      this.fetchingEvents = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/addons/eventcalendar/external/${this.providers_id}/fetchEvents?filter[category]=${this.categories_name}`
      );
      this.provider_events = response.data.data;
      this.fetchingEvents = false;
    },

    async importSelectedEvents() {
      const userChoice = await this.$dialog.confirm({
        text: this.$t("importEventsQuestion"),
        title: this.$t("areYouSure"),
        waitForResult: true,
      });

      if (userChoice) {
        // POST /companies/{id}/addons/eventcalendar/external/{providerId}/storeFetchedEvents mit Query-Parameter "filter" angelegt, z.B. ?filter[category]=GOLFSCHNUPPERKURS.
        this.importingEvents = true;
        let response = await this.postRequest(
          `companies/${this.companyID}/addons/eventcalendar/external/${this.providers_id}/storeFetchedEvents?filter[category]=${this.categories_name}`,
          { events: this.provider_events }
        );

        if (response.status === 200) {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = this.$t("messageImported");
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 8000;
          this.$emit("itemsImported");
        } else {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = this.$t("messageError");
          this.$root.snackbarGlobal.errorMessage = response.data.message;
          this.$root.snackbarGlobal.snackbarColor = "error";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
        this.importingEvents = false;
      }
    },
  },
  mixins: [apiRequest, helpers],
};
</script>
