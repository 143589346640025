<template>
  <v-container class="mt-6">
    <template v-if="hasAddon()">
      <DataTable
        @tableEvent="emittedAction"
        :items="items"
        :headers="headers"
        :edit="true"
        :trash="true"
        :disableSearch="true"
        :preloader="preloader"
      />

      <v-container>
        <v-btn large color="green" dark class="mx-1" @click="addItem">
          {{ $t("addEvent") }}
          <v-icon right dark>mdi-plus</v-icon>
        </v-btn>

        <v-btn large color="green" dark class="mx-1" @click="importEvents">
          {{ $t("importEvents") }}
          <v-icon right dark>mdi-database-import</v-icon>
        </v-btn>
      </v-container>

      <CreateEvent
        :trigger="createItem"
        @closeModal="createItem = false"
        @emit="emittedAction"
        :key="componentKey"
      />

      <ImportEvents
        :trigger="importItems"
        @closeModal="
          importItems = false;
          getData();
        "
        @emit="emittedAction"
        :key="importKey"
      />

      <UpdateEvent
        v-if="editItem && items[itemIndex].id"
        :trigger="editItem"
        :itemData="itemData"
        :companyID="companyID"
        :id="itemId"
        @updated="getData()"
        @closeModal="editItem = false"
      ></UpdateEvent>
    </template>

    <template v-else>
      <p>
        Das Modul ist nicht verfügbar.<br />Bei Fragen oder Interesse
        kontaktiere bitte deinen FACEFORCE Berater.
      </p>

      <MarketingFallback
        :buyAction="true"
        title="mitfitEVENTCALENDAR"
        content="mitfitEVENTCALENDAR ist ein digitales System, welches über alle denkbaren digitalen Kanäle Buchungen ermöglicht."
        url="https://www.face-force.de/unternehmen/kontakt-anfahrt/"
      />
    </template>
  </v-container>
</template>

<script>
import store from "@/store";
import DataTable from "@/components/ui/DataTable.vue";
import CreateEvent from "@/components/addons/event-calendar/CreateEvent.vue";
import ImportEvents from "@/components/addons/event-calendar/ImportEvents.vue";
import UpdateEvent from "@/components/addons/event-calendar/UpdateEvent.vue";
import MarketingFallback from "@/components/addons//MarketingFallback";
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "AddonEventCalendar",
  components: {
    DataTable,
    CreateEvent,
    UpdateEvent,
    ImportEvents,
    MarketingFallback,
  },
  data() {
    return {
      pageTitle: "Events",
      pageDescription: "Hier können sie die Events des Unternehmens verwalten.",
      title: "",
      preloader: false,
      fetchinAddons: false,
      companyID: store.getters["auth/current_sid"],
      itemId: "",
      itemIndex: "",
      itemData: "",
      editItem: false,
      createItem: false,
      importItems: false,
      componentKey: 0,
      importKey: 1,
      items: [],
      addons: [],
      headers: [
        { text: "ID", value: "id", sortable: false, width: "20px" },
        {
          text: this.$t("source"),
          value: "isImported",
          sortable: false,
          width: "50px",
        },
        { text: "Titel", value: "title", sortable: false },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "right",
          width: "50px",
        },
      ],
    };
  },

  created: function () {
    this.getAddons();
    this.getData();

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getAddons();
        this.getData();
      }
    );
  },

  methods: {
    hasAddon() {
      // check if addon id 6 is available in this.addons
      let addon = this.addons.find((addon) => addon.id == "9");
      if (addon) {
        return true;
      } else {
        return false;
      }
    },

    async getAddons() {
      this.fetchinAddons = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/addons`
      );
      this.addons = response.data.data;
      this.fetchinAddons = false;
    },

    async getData() {
      this.preloader = true;
      let response = await this.getRequest(
        "companies/" + this.companyID + "/addons/eventcalendar/events"
      );
      this.items = response.data.data;
      this.preloader = false;
    },

    emittedAction(value) {
      if (value.action === "editItem") {
        this.itemId = value.itemId;
        this.itemIndex = value.itemIndex;
        this.itemData = this.items[value.itemIndex];
        this.editItem = true;
        this.componentKey++;
      } else if (value.action === "createdItem") {
        this.items.push(value.newPost);
        this.createItem = false;
      } else if (value.action === "deleteItem") {
        this.deletePrompt(value.itemId); // reload
      } else if (value.action === "closeModal") {
        this.createItem = false;
        this.editItem = false;
      } else if (value.action === "itemPatched") {
        this.getData(); // reload
      }
    },

    importEvents() {
      console.log("Import Items");
      this.importItems = true;
      this.importKey++;
    },
    addItem() {
      console.log("Add Item");
      this.createItem = true;
      this.componentKey++;
    },
    async deletePrompt(id) {
      const userChoice = await this.$dialog.confirm({
        text: "Soll das Event wirklich gelöscht werden?",
        title: "Achtung!",
        waitForResult: true,
      });

      if (userChoice) {
        // okay
        /* USEING REUSEABLE MIXIN METHOD */
        this.preloader = true;
        let response = await this.deleteRequest(
          "companies/" + this.companyID + "/addons/eventcalendar/events/" + id
        ); // await data from mixin
        this.preloader = false;

        if (response) {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = "Eintrag wurde gelöscht";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
        }
        this.getData(); // reload
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },
  },

  mixins: [apiRequest],
};
</script>

<style>
.note p {
  margin-bottom: 0 !important;
}
</style>
