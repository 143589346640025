<template>
  <v-dialog v-model="trigger" persistent scrollable max-width="800px">
    <v-card>
      <v-app-bar flat color="primary">
        <v-toolbar-title class="title white--text pl-0">
          {{ $t("addNewDate") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('closeModal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-form id="form" ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12">
              <v-text-field
                :label="$t('date')"
                v-model="eventDate"
                type="date"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                :label="$t('startTime')"
                v-model="startTime"
                type="time"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                :label="$t('endTime')"
                v-model="endTime"
                type="time"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                :label="$t('maxSeats')"
                v-model="maxSeats"
                type="number"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                :label="$t('totalRegistrations')"
                v-model="totalRegistrations"
                type="number"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn
                large
                color="green"
                dark
                class="mx-1"
                @click="validate"
                :loading="preloader"
                :disabled="!valid"
              >
                {{ $t("safe") }}
                <v-icon right dark>mdi-check-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "@/store";
import apiRequest from "@/mixins/apiRequest";
export default {
  name: "CreateEvent",
  props: ["trigger", "eventId"],
  data() {
    return {
      companyID: store.getters["auth/current_sid"],
      route:
        "companies/" +
        store.getters["auth/current_sid"] +
        "/addons/eventcalendar/events/" +
        this.eventId +
        "/timeslots",
      preloader: false,
      valid: false,
      eventDate: "",
      startTime: "",
      endTime: "",
      maxSeats: "",
      totalRegistrations: "0",
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.postData();
      }
    },

    async postData() {
      let formData = new FormData();
      formData.append("eventDate", this.eventDate);
      formData.append("startTime", this.startTime);
      formData.append("endTime", this.endTime);
      formData.append("maxSeats", this.maxSeats);
      formData.append("totalRegistrations", this.totalRegistrations);
      this.preloader = true;
      let response = await this.postRequest(this.route, "", formData);
      this.preloader = false;
      let newPost = response.data.data;
      this.$emit("emit", { action: "createdItem", newPost: newPost });

      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText =
        "Ein neuer Datensatz wurde angelegt.";
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;
    },
  },
  mixins: [apiRequest],
};
</script>
