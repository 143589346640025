<template>
  <v-dialog v-model="trigger" persistent scrollable max-width="800px">
    <v-card>
      <v-app-bar flat color="primary">
        <v-toolbar-title class="title white--text pl-0">
          {{ this.itemData.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('closeModal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-alert
              v-if="isImported === '1'"
              border="top"
              color="red lighten-2"
              class="mt-6"
              dark
            >
              {{ $t("importedEventInfo") }}
            </v-alert>

            <v-form
              id="form"
              ref="form"
              class="mt-6"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    label="Name des Events"
                    v-model="title"
                    type="text"
                    :disabled="isImported === '1' ? true : false"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>

                <!-- WERDEN ZUNÄCHST NUR VON DER FF AKTUALISIERT
                <v-col cols="12">
                  <v-textarea
                    :label="$t('custom_text_confirmation_email_subject')"
                    v-model="custom_text_confirmation_email_subject"
                    :rules="[rules.required]"
                  ></v-textarea>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    :label="$t('custom_text_confirmation_email_subject')"
                    v-model="custom_text_confirmation_email_subject"
                    type="text"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-textarea
                    :label="$t('custom_text_confirmation_email_body')"
                    v-model="custom_text_confirmation_email_body"
                    :rules="[rules.required]"
                  ></v-textarea>
                </v-col>
              -->

                <v-col cols="12">
                  <v-btn
                    large
                    color="green"
                    dark
                    @click="validate"
                    :loading="preloader"
                    :disabled="!valid || isImported === '1' ? true : false"
                  >
                    {{ $t("safe") }}
                    <v-icon right dark>mdi-check-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>

          <v-col cols="12">
            <EventTimeslots
              :eventId="id"
              :isImported="isImported"
              :companyID="companyID"
            ></EventTimeslots>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import EventTimeslots from "@/components/addons/event-calendar/EventTimeslots.vue";
export default {
  name: "UpdateEvent",

  components: {
    EventTimeslots,
  },

  props: ["trigger", "itemData", "id", "companyID"],

  data() {
    return {
      title: this.itemData.title,
      isImported: this.itemData.isImported,
      custom_text_booking_thankyou: this.itemData.custom_text_booking_thankyou,
      custom_text_confirmation_email_body:
        this.itemData.custom_text_confirmation_email_body,
      custom_text_confirmation_email_subject:
        this.itemData.custom_text_confirmation_email_subject,
      route:
        "companies/" +
        this.$route.params.id +
        "/addons/eventcalendar/events/" +
        this.itemData.id,
      valid: false,
      rules: {
        required: (value) => !!value || "Required.",
      },
      preloader: false,
    };
  },

  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.updateData();
      }
    },

    async updateData() {
      this.preloader = true;

      var dataArr = new URLSearchParams();
      dataArr.append("title", this.title);

      let response = await this.patchRequest(this.route, dataArr);

      /* CALL SNACKBAR */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;

      this.$emit("updated", response);
      this.preloader = false;
    },
  },
  mixins: [apiRequest],
};
</script>
