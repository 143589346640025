<template>
  <v-dialog v-model="trigger" persistent scrollable max-width="800px">
    <v-card>
      <v-app-bar flat color="primary">
        <v-toolbar-title class="title white--text pl-0">
          Events von einem Drittanbieter importieren
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('closeModal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-row v-if="!preloader">
          <v-col cols="12" class="pt-6">
            {{ $t("importEventsInfo") }}
          </v-col>
          <v-col v-if="!providers_id" cols="12">
            <v-select
              v-model="providers_id"
              :items="data"
              item-text="name"
              item-value="providers_id"
              label="Anbieter"
              :hint="$t('selectProviderHint')"
              :persistent-hint="true"
              outlined
              dense
            ></v-select>
          </v-col>

          <v-col v-if="providers_id === '15'" cols="12">
            <ImportEventsPC @itemsImported="$emit('closeModal')" />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "@/store";
import apiRequest from "@/mixins/apiRequest";
import ImportEventsPC from "@/components/addons/event-calendar/pc-caddie/ImportEventsPC.vue";
export default {
  name: "ImportEvents",
  props: ["trigger"],
  components: {
    ImportEventsPC,
  },
  data() {
    return {
      data: [],
      providers_id: "",
      companyID: store.getters["auth/current_sid"],
      preloader: true,
    };
  },
  mounted() {
    this.getProviders();
  },
  methods: {
    async getProviders() {
      // GET /companies/[id]/externalApis?filter[scopes]=events
      this.preloader = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/externalApis?filter[scopes]=events`
      );
      this.data = response.data.data;
      this.preloader = false;
    },
  },
  mixins: [apiRequest],
};
</script>
