<template>
  <v-dialog v-model="trigger" persistent scrollable max-width="800px">
    <v-card>
      <v-app-bar flat color="primary">
        <v-toolbar-title class="title white--text pl-0">
          {{ $t("date") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('closeModal')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>

      <v-card-text>
        <v-alert
          v-if="isImported === '1'"
          border="top"
          color="red lighten-2"
          class="mt-6"
          dark
        >
          {{ $t("importedEventInfo") }}
        </v-alert>

        <v-form
          id="form"
          ref="form"
          class="mt-6"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                :label="$t('date')"
                v-model="eventDate"
                type="date"
                :disabled="isImported === '1' ? true : false"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                :label="$t('startTime')"
                v-model="startTime"
                type="time"
                :disabled="isImported === '1' ? true : false"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                :label="$t('endTime')"
                v-model="endTime"
                type="time"
                :disabled="isImported === '1' ? true : false"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                :label="$t('maxSeats')"
                v-model="maxSeats"
                type="number"
                :disabled="isImported === '1' ? true : false"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                :label="$t('totalRegistrations')"
                v-model="totalRegistrations"
                type="number"
                :disabled="isImported === '1' ? true : false"
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-btn
                large
                color="green"
                class="mx-1 white--text"
                @click="validate"
                :loading="preloader"
                :disabled="!valid || isImported === '1' ? true : false"
              >
                {{ $t("safe") }}
                <v-icon right dark>mdi-check-circle-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import store from "@/store";
import apiRequest from "@/mixins/apiRequest";

export default {
  name: "UpdateTimeslot",

  components: {},

  props: ["trigger", "itemData", "id", "isImported"],

  data() {
    return {
      preloader: false,
      companyID: store.getters["auth/current_sid"],
      title: "",
      route:
        "companies/" +
        store.getters["auth/current_sid"] +
        "/addons/eventcalendar/events/" +
        this.itemData.id +
        "/timeslots/" +
        this.id,
      valid: false,
      eventDate: this.itemData.eventDate,
      startTime: this.itemData.startTime,
      endTime: this.itemData.endTime,
      maxSeats: this.itemData.maxSeats,
      totalRegistrations: this.itemData.totalRegistrations,
      rules: {
        required: (value) => !!value || "Required.",
      },
    };
  },

  methods: {
    validate() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.updateData();
      }
    },

    async updateData() {
      this.preloader = true;

      var dataArr = new URLSearchParams();
      dataArr.append("eventDate", this.eventDate);
      dataArr.append("startTime", this.startTime);
      dataArr.append("endTime", this.endTime);
      dataArr.append("maxSeats", this.maxSeats);
      dataArr.append("totalRegistrations", this.totalRegistrations);

      let response = await this.patchRequest(this.route, dataArr);

      /* CALL SNACKBAR */
      this.$root.snackbarGlobal.snackbar = true;
      this.$root.snackbarGlobal.snackbarText = this.$t("messageUpdated");
      this.$root.snackbarGlobal.snackbarColor = "primary";
      this.$root.snackbarGlobal.snackbarTimeout = 5000;

      this.$emit("updated", response);
      this.preloader = false;
    },
  },
  mixins: [apiRequest],
};
</script>
