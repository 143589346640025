<template>
  <v-container class="mt-6">
    <DataTable
      :headers="headers"
      :items="data"
      :preloader="fetchingData"
      :edit="false"
      :watch="true"
      :trash="false"
      :copy="false"
      :leads="false"
      :preview="false"
      :stats="false"
      @tableEvent="emittedTableEvent"
    ></DataTable>
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import DataTable from "@/components/ui/DataTable";

export default {
  name: "EventBookings",

  components: {
    DataTable,
  },

  data() {
    return {
      pageTitle: this.$t("EventBookings"),
      fetchingData: false,
      companyID: store.getters["auth/current_sid"],
      data: [],
      headers: [],
    };
  },

  created: function () {
    this.$store.commit("companyChangeOption/SET_ALLOWED", true);

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getData();
      }
    );

    this.getData();
  },

  methods: {
    newItemAdded() {
      this.dialogManualLead = false;
      this.getData();
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/addons/eventcalendar/bookings`
      );
      this.fetchingData = false;
      this.data = response.data.data;

      this.headers = [
        /* { text: 'id', value: 'id'}, */
        { text: "Event", value: "eventTitle", width: "200px" },
        { text: this.$t("firstName"), value: "leadFirstname", width: "150px" },
        { text: this.$t("lastName"), value: "leadLastName", width: "150px" },
        { text: this.$t("date"), value: "eventDate", width: "100px" },
        { text: this.$t("startTime"), value: "eventStartTime" },
      ];
    },

    emittedTableEvent(obj) {
      this.leadId = obj.itemId;
      this.leadCampaign = obj.itemData.campaign;

      //finde the real data in array
      //vorher bin ich über INDEX gegangen, was sich aber als falsch herausgestellt hatte, da der INDEX auf die aktuelle Page der Tabelle bezogen war.
      this.data.forEach((item) => {
        if (item.id === this.leadId) {
          this.leadData = item; // the data of that item
        }
      });

      this.dialog = true;
      this.componentKey += 1; // forces the component (here LeadDetail) to re-render -> https://michaelnthiessen.com/force-re-render/
    },
  },

  mixins: [manageApi],
};
</script>
