<template>
  <v-container v-if="!fetchinData">
    <template v-if="hasAddon()">
      <PageHeader
        :pageTitle="pageTitle"
        :pageDescription="$t('pageDescriptions.addonsEvents')"
      />

      <template>
        <v-tabs v-model="tab" align-with-title dark background-color="primary">
          <v-tabs-slider color="lime accent-2"></v-tabs-slider>
          <v-tab v-for="(item, index) in items" :key="index">
            {{ item.name }}
          </v-tab>
        </v-tabs>
      </template>

      <v-tabs-items v-model="tab" class="grey lighten-4">
        <v-tab-item v-for="(item, index) in items" :key="index">
          <v-card flat class="grey lighten-4">
            <component v-bind:is="item.component"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>

    <template v-else>
      <PageHeader :pageTitle="pageTitle" pageDescription="" />
      <p>
        Das Modul ist nicht verfügbar.<br />Bei Fragen oder Interesse
        kontaktiere bitte deinen FACEFORCE Berater.
      </p>

      <MarketingFallback
        :buyAction="true"
        title="mitfitEVENTS"
        content="mitfitEVENTS ist ein digitales System, welches über alle denkbaren digitalen Kanäle Eventbuchungen ermöglicht."
        url="https://www.face-force.de/online-marketing/"
      />
    </template>
  </v-container>
</template>

<script>
import store from "@/store";
import manageApi from "@/mixins/manageApi"; // import mixin (for api requests)
import PageHeader from "@/components/ui/PageHeader";
import AddonEventCalendar from "@/views/addons/addon-eventcalendar/AddonEventCalendar";
import EventBookings from "@/views/addons/addon-eventcalendar/bookings/EventBookings";
import MarketingFallback from "@/components/addons//MarketingFallback";

export default {
  name: "AddonMember",

  components: {
    PageHeader,
    AddonEventCalendar,
    EventBookings,
    MarketingFallback,
  },

  data() {
    return {
      pageTitle: "mitfitEVENTS",
      pageDescription:
        "Pellentesque in ipsum id orci porta dapibus. Cras ultricies ligula sed magna dictum porta.",
      fetchinData: false, // should be true when fetching data
      companyID: store.getters["auth/current_sid"],
      addons: [], // wrapper for fetched data
      tab: null,
      items: [
        {
          name: this.$t("Bookings"),
          component: "EventBookings",
        },
        {
          name: this.$t("Events"),
          component: "AddonEventCalendar",
        },
      ],

      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
    };
  },

  props: [],

  created: function () {
    // allow to change company?
    this.$store.commit("companyChangeOption/SET_ALLOWED", true);

    /* VUEX store watcher. It fires when current_sid (current studioID) changes */
    this.$store.watch(
      () => store.getters["auth/current_sid"],
      (value) => {
        this.companyID = value;
        this.getAddons();
      }
    );

    this.getAddons();
  },

  methods: {
    clientBlockHack() {
      return true;
    },

    hasAddon() {
      // check if addon id 6 is available in this.addons
      let addon = this.addons.find((addon) => addon.id == "9");
      if (addon) {
        return true;
      } else {
        return false;
      }
    },

    async getAddons() {
      this.fetchinData = true;
      let response = await this.getRequest(
        `companies/${this.companyID}/addons`
      );
      this.addons = response.data.data;
      this.fetchinData = false;
    },
  },

  mixins: [manageApi],
};
</script>
