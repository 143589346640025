<template>
  <div id="page">
    <v-row>
      <v-col cols="12" v-if="!preloader">
        <h2 class="my-3">{{ $t("dates") }}</h2>
        <p>{{ $t("eventDatesInfo") }}</p>

        <DataTable
          @tableEvent="emittedAction"
          :items="items"
          :headers="headers"
          :edit="true"
          :trash="true"
          :disableSearch="true"
          :preloader="preloader"
        />
        <v-container>
          <v-btn
            large
            color="green"
            class="mx-1 white--text"
            :disabled="isImported === '1' ? true : false"
            @click="addItem"
          >
            {{ $t("addEventTimeslot") }}
            <v-icon right dark>mdi-plus</v-icon>
          </v-btn>

          <v-btn
            large
            color="green"
            class="mx-1 white--text"
            :disabled="isImported === '1' ? false : true"
            @click="syncItem"
            :loading="syncing"
          >
            {{ $t("syncEvents") }}
            <v-icon right dark>mdi-cloud-sync</v-icon>
          </v-btn>
        </v-container>
      </v-col>
    </v-row>

    <CreateTimeslot
      v-if="!preloader"
      :trigger="createItem"
      :eventId="eventId"
      @closeModal="createItem = false"
      @emit="emittedAction"
    />

    <UpdateTimeslot
      v-if="editItem && items[itemIndex].id"
      :trigger="editItem"
      :itemData="itemData"
      :id="itemId"
      :isImported="isImported"
      @updated="getData()"
      @closeModal="editItem = false"
    ></UpdateTimeslot>
  </div>
</template>

<script>
import DataTable from "@/components/ui/DataTable.vue";
import CreateTimeslot from "@/components/addons/event-calendar/CreateTimeslot.vue";
import UpdateTimeslot from "@/components/addons/event-calendar/UpdateTimeslot.vue";
import apiRequest from "@/mixins/apiRequest";

export default {
  name: "EventTimeslots",
  components: {
    DataTable,
    CreateTimeslot,
    UpdateTimeslot,
  },
  props: ["eventId", "isImported", "companyID"],
  data() {
    return {
      title: "",
      preloader: false,
      itemId: "",
      itemIndex: "",
      itemData: "",
      providers_id: "",
      eventTitle: "",
      editItem: false,
      createItem: false,
      syncing: false,
      items: [],
      headers: [
        { text: this.$t("date"), value: "eventDate", sortable: false },
        { text: this.$t("startTime"), value: "startTime", sortable: false },
        { text: this.$t("endTime"), value: "endTime", sortable: false },
        { text: this.$t("maxSeats"), value: "maxSeats", sortable: false },
        {
          text: this.$t("totalRegistrations"),
          value: "totalRegistrations",
          sortable: false,
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "right",
          width: "50px",
        },
      ],
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async getData() {
      // /companies/{id}/addons/eventcalendar/events/{id}/timeslots
      this.preloader = true;
      let response = await this.getRequest(
        "companies/" +
          this.companyID +
          "/addons/eventcalendar/events/" +
          this.eventId +
          "/timeslots"
      );
      this.items = response.data.data;
      this.providers_id = response.data.data[0].providers_id;
      this.eventTitle = response.data.data[0].eventTitle;
      this.preloader = false;
    },
    emittedAction(value) {
      if (value.action === "editItem") {
        this.itemId = value.itemId;
        this.itemIndex = value.itemIndex;
        this.itemData = this.items[value.itemIndex];
        this.editItem = true;
      } else if (value.action === "createdItem") {
        this.items.push(value.newPost);
        this.createItem = false;
      } else if (value.action === "deleteItem") {
        this.deletePrompt(value.itemId); // reload
      } else if (value.action === "closeModal") {
        this.createItem = false;
        this.editItem = false;
      } else if (value.action === "itemPatched") {
        this.getData(); // reload
      }
    },
    addItem() {
      this.createItem = true;
    },
    syncItem() {
      // POST /companies/{id}/addons/eventcalendar/external/${this.providers_id}/storeFetchedEvents?filter[category]=${this.eventTitle}

      this.$dialog
        .confirm({
          text: this.$t("syncEventsQuestion"),
          title: this.$t("areYouSure"),
          waitForResult: true,
        })
        .then((userChoice) => {
          if (userChoice) {
            this.syncing = true;
            this.postRequest(
              "companies/" +
                this.companyID +
                "/addons/eventcalendar/external/" +
                this.providers_id +
                "/storeFetchedEvents?filter[category]=" +
                this.eventTitle
            ).then(() => {
              this.preloader = false;
              this.$root.snackbarGlobal.snackbar = true;
              this.$root.snackbarGlobal.snackbarText =
                "Events wurden synchronisiert";
              this.$root.snackbarGlobal.errorMessage = "";
              this.$root.snackbarGlobal.snackbarColor = "primary";
              this.$root.snackbarGlobal.snackbarTimeout = 5000;
            });
            this.syncing = false;
            this.getData();
          }
        });
    },
    async deletePrompt(id) {
      const userChoice = await this.$dialog.confirm({
        text: this.$t("dateDeletionQuestion"),
        title: this.$t("attention"),
        waitForResult: true,
      });

      if (userChoice) {
        // okay
        /* USEING REUSEABLE MIXIN METHOD */
        this.preloader = true;
        let response = await this.deleteRequest(
          "companies/" +
            this.companyId +
            "/addons/eventcalendar/events/" +
            this.eventId +
            "/timeslots/" +
            id
        ); // await data from mixin
        this.preloader = false;

        if (response) {
          this.$root.snackbarGlobal.snackbar = true;
          this.$root.snackbarGlobal.snackbarText = "Eintrag wurde gelöscht";
          this.$root.snackbarGlobal.errorMessage = "";
          this.$root.snackbarGlobal.snackbarColor = "primary";
          this.$root.snackbarGlobal.snackbarTimeout = 5000;
          this.getData(); // reload
        }
      } else {
        // abort
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText = "Vorgang abgebrochen!";
        this.$root.snackbarGlobal.errorMessage = "";
        this.$root.snackbarGlobal.snackbarColor = "primary";
        this.$root.snackbarGlobal.snackbarTimeout = 5000;
      }
    },
  },

  mixins: [apiRequest],
};
</script>

<style>
.note p {
  margin-bottom: 0 !important;
}
</style>
