var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',[_c('v-col',{staticClass:"pt-6",attrs:{"cols":"12"}},[_c('h2',[_vm._v(_vm._s(_vm.$t("eventCategory")))]),_c('v-select',{staticClass:"my-4",attrs:{"loading":_vm.preloader,"items":_vm.data,"item-text":"description","item-value":"initial","label":_vm.$t('eventCategory'),"hint":_vm.$t('selectEventCategoryHint'),"persistent-hint":true,"outlined":"","dense":""},on:{"change":function($event){return _vm.fetchEvents()}},model:{value:(_vm.categories_name),callback:function ($$v) {_vm.categories_name=$$v},expression:"categories_name"}})],1),(_vm.provider_events.length > 0)?_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"mb-4"},[_vm._v(" Folgende Termine werden von "+_vm._s(_vm.categories_name)+" importiert: ")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":[
        { text: 'Datum', value: 'tourn_date_first' },
        { text: 'Startzeit', value: 'startinfozeit' },
      ],"items":_vm.provider_events,"item-key":"id","loading":_vm.fetchingEvents},scopedSlots:_vm._u([{key:`item.tourn_date_first`,fn:function({ item }){return [(
            item.tourn_date_first &&
            item.tourn_date_first !== '0000-00-00 00:00:00'
          )?_c('span',[_vm._v(_vm._s(_vm.formatDateNoTime(item.tourn_date_first)))]):_vm._e()]}}],null,true)}),_c('v-btn',{staticClass:"my-4",attrs:{"large":"","color":"green","dark":"","loading":_vm.importingEvents},on:{"click":_vm.importSelectedEvents}},[_vm._v(" "+_vm._s(_vm.$t("importEvents"))+"* "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v("mdi-database-import")])],1),_c('br'),_c('small',[_vm._v("*) "+_vm._s(_vm.$t("importEventsInfo2")))])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }